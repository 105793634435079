import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import FormSuccess from "../../components/modal/register-acc/modalSuccess";
import { setToast } from "../client/actions";
import FormQuestion from "./step2/formQuestion";
import FormCheckInforRes from "./step3/formCheckInforRes";
import FormSignCont from "./step3/formSignCont";

import _ from "lodash";
import { translate } from "react-i18next";
import PerfectScrollbar from "react-perfect-scrollbar";
import { handleApiErrors } from "../../lib/api-errors";
import { makeGetDataAccount } from "../../lib/selector";
import { removeAccent } from "../../util";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function PageSignContract(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    orcData,
    subStep,
    handlePreSubStep,
    handleNextSubStep,
    dataAccount,
    prePageFirst,
    t,
    handleStopTime,
  } = props;

  const [isCheckQuest, setIsCheckQuest] = useState(false);
  const [success, setSuccess] = useState(false);
  const scrollRef = useRef(null);
  const headerRef = useRef();

  const [scrollHeight, setScrollHeight] = useState("100vh");
  const preSubStep = usePrevious(subStep);

  useEffect(() => {
    if (!dataAccount || subStep < 7) {
      if (subStep > 5) {
        history.push("/open-account/register-service");
      } else history.push("/open-account/check-info");
      return;
    }
  }, []);

  useEffect(() => {
    const calculateScrollHeight = () => {
      const headerHeight =
        headerRef.current?.getBoundingClientRect().height || 0;
      const extraHeight = window.innerWidth < 768 ? 92 : 0;
      setScrollHeight(`calc(100vh - ${headerHeight + 120 + extraHeight}px)`);
    };

    // Tính toán chiều cao ban đầu và khi resize
    calculateScrollHeight();
    window.addEventListener("resize", calculateScrollHeight);

    // Cuộn về đầu khi thay đổi subStep
    if (subStep && !_.isEqual(subStep, preSubStep) && scrollRef.current) {
      scrollRef.current.scrollTop = 0;
    }

    return () => {
      window.removeEventListener("resize", calculateScrollHeight);
    };
  }, [subStep]);

  const openAccount = (otp) => {
    const params = {
      user: "back",
      cmd: "OPEN_INDI_ACCOUNT",
      sid: dataAccount?.S_ID,
      ekyc: "1",
      opt: otp,
      param: {
        C_EDIT_FLAG: dataAccount?.C_EDIT_FLAG,
        C_CARD_ID: dataAccount?.C_CARD_ID,
        C_FULL_NAME: dataAccount?.C_FULL_NAME,
        C_CUST_BIRTH_DAY: dataAccount?.C_CUST_BIRTH_DAY,
        C_ISSUE_DATE: dataAccount?.C_ISSUE_DATE,
        C_ISSUE_EXPIRE: dataAccount?.C_ISSUE_EXPIRE,
        C_ISSUE_PLACE: dataAccount?.C_ISSUE_PLACE,
        C_GENDER: dataAccount?.C_GENDER === "NAM" ? "M" : "F",
        C_PROVINCE: dataAccount?.C_PROVINCE,
        C_ADDRESS: dataAccount?.C_ADDRESS,
        C_CONTACT_ADDRESS: dataAccount?.C_CONTACT_ADDRESS,
        C_MOBILE: dataAccount?.C_MOBILE,
        C_EMAIL: dataAccount?.C_EMAIL,
        C_MOBILE_TRADING_PASSWORD: dataAccount?.C_MOBILE_TRADING_PASSWORD,
        C_BANK_ACCOUNT_NAME:
          dataAccount?.C_DKTK === "1" && dataAccount?.C_FULL_NAME
            ? removeAccent(dataAccount?.C_FULL_NAME.toUpperCase())
            : "",
        C_BANK_CODE: dataAccount?.C_BANK_CODE,
        C_BANK_ACCOUNT: dataAccount?.C_BANK_ACCOUNT,
        C_BANK_BRANCH: dataAccount?.C_BRANCH_CODE,
        C_BANK_PROVINCE: dataAccount?.C_BANK_PROVINCE,

        C_SALE_ID: dataAccount?.C_SALE_ID,
        C_ANH_MAT_TRUOC: `https://onboarding.upstock.com.vn${dataAccount?.C_ANH_MAT_TRUOC}`,
        C_ANH_MAT_SAU: `https://onboarding.upstock.com.vn${dataAccount?.C_ANH_MAT_SAU}`,
        C_ANH_CHAN_DUNG: `https://onboarding.upstock.com.vn${dataAccount?.C_ANH_CHAN_DUNG}`,
        C_AVATAR_IMG_DEFAULT:
          "/img/20082024/e1d44c95-7201-4f25-a643-a65ddf17ad04", // AVA mặc định
        // C_ANH_CHU_KY: http://202.124.204.208:8998/,
        C_ACCOUNT: dataAccount?.C_ACCOUNT,
        C_PASSWORD: "123456",
        C_OPEN_MARGIN: dataAccount?.C_ACCOUNT_TYPE,
        C_SERVICE_SALE: dataAccount?.C_SALE_ID ? "1" : "0",
        C_SERVICE_SMS: dataAccount?.C_DKSMS,
        C_SERVICE_ADVANCE: dataAccount?.C_UTB,
        C_SERVICE_INTERNET: dataAccount?.C_GDTT,
        C_SERVICE_CALL: dataAccount?.C_GDTD,
        C_SERVICE_EMAIL: dataAccount?.C_GDE,
        C_RECEIVE_BANK_ACCOUNT: dataAccount?.C_DKTK,
        REF_ID: dataAccount?.REF_ID,
      },
    };

    const request = fetch(`${apiUrl}/openAccount`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiOpenAcc(request);
  };

  const handleApiOpenAcc = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setSuccess(true);
          handleStopTime();
        } else {
          _handleToast(json.sRs, "error");
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <>
      <div
        className={`md:col-span-2 grid gap-2 auto-rows-max ${
          !isCheckQuest ? "box-top_margin" : ""
        }`}
        ref={headerRef}
      >
        <div className=" w-100 grid grid-cols-5 gap-1">
          {!isCheckQuest && (
            <div className="col-span-5 style-bg-header">
              <div className="d-flex align-items-center rounded-full border-skin-gray text-xs pl-2 pr-2">
                {subStep - 6} / 2
              </div>
              <span className="text-base font-semibold text-skin-title ml-2">
                {subStep === 7
                  ? t("check-registration-info")
                  : subStep === 8
                  ? t("sign-contract")
                  : ""}
              </span>
            </div>
          )}

          {!isCheckQuest && subStep === 7 && (
            <span
              className="col-span-5 text-xs text-skin-body"
              style={{
                marginTop: "12px",
              }}
            >
              *{t("check-info")}
            </span>
          )}
        </div>
      </div>
      <PerfectScrollbar
        containerRef={(el) => {
          scrollRef.current = el;
        }}
        className="md:col-span-2 gap-4 auto-rows-max"
        style={{ height: scrollHeight }}
      >
        {!isCheckQuest && subStep === 7 && (
          <FormCheckInforRes
            handleNextSubStep={handleNextSubStep}
            handlePreSubStep={handlePreSubStep}
            orcData={orcData}
            openFormQuestion={() => setIsCheckQuest(true)}
          />
        )}
        {isCheckQuest && subStep === 7 && (
          <FormQuestion
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
            closeFormQuestion={() => setIsCheckQuest(false)}
          />
        )}
        {subStep === 8 && (
          <FormSignCont
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
            openAccount={openAccount}
            orcData={orcData}
          />
        )}
        {success && <FormSuccess handlePreSubStep={handlePreSubStep} />}
      </PerfectScrollbar>
    </>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(PageSignContract)
);
