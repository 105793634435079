import * as _ from "lodash";
import packageJson from "../../package.json";

import MD5 from "crypto-js/md5";

export function checkVersion() {
  const version = localStorage.getItem("version");

  if (packageJson.version !== version) {
    localStorage.clear();
    localStorage.setItem("version", packageJson.version);
    return false;
  }
  return true;
}

/**
 * !function
 */

export function addZero(i) {
  if (i < 10) {
    i = "0" + i;
  }
  return i;
}

function pick(...manyMoreArgs) {
  let a = manyMoreArgs,
    c,
    r,
    u = a.length;
  for (c = 0; c < u; c++)
    if (((r = a[c]), void 0 !== r && null !== r)) return r;
}

function FormatCurrency(num, delimitor, separate) {
  let sign, tail, ret_value;
  num = num.toString().replace(/\$|\,/g, "");
  if (isNaN(num)) num = "0";
  sign = num == (num = Math.abs(num));
  let str = num.toString();
  let arr_str = str.split(separate);
  if (arr_str.length > 1) {
    tail = String(arr_str[1]);
    if (tail.length < 2) {
      tail = tail + "0";
    }
  } else {
    tail = "";
  }
  num = arr_str[0];
  for (let i = 0; i < Math.floor((num.length - (1 + i)) / 3); i++)
    num =
      num.substring(0, num.length - (4 * i + 3)) +
      delimitor +
      num.substring(num.length - (4 * i + 3));

  if (tail == "") ret_value = (sign ? "" : "-") + num;
  else ret_value = (sign ? "" : "-") + num + separate + tail;
  return ret_value;
}

export function formatVolume10(number) {
  let vTemp = StringToInt(number) * 10;
  let vNumber = FormatCurrency(vTemp.toString(), ",", ".");
  return vNumber.substring(0, vNumber.length - 1);
}

export function numberFormat(h, c = 0, t = "", r, u) {
  if (h === "ATO" || h === "ATC") return h;
  h = +h || 0;
  c = +c;
  let w = (h.toString().split(".")[1] || "").split("e")[0].length,
    n,
    g,
    d = h.toString().split("e");

  g = (Math.abs(d[1] ? d[0] : h) + Math.pow(10, -Math.max(c, w) - 1)).toFixed(
    c
  );
  w = String(parseInt(g, 10));
  n = 3 < w.length ? w.length % 3 : 0;
  r = pick(r, ".");
  u = pick(u, ",");
  h = (0 > h ? "-" : "") + (n ? w.substr(0, n) + u : "");
  h += w.substr(n).replace(/(\d{3})(?=\d)/g, "$1" + u);
  c && (h += r + g.slice(-c));
  d[1] && 0 !== +h && (h += "e" + d[1]);
  if (h == 0) return t;
  return h;
}

export function StringToInt(pString) {
  pString = "" + pString;
  pString = pString.replace(/,/g, "");
  let vInt = parseInt(pString, 10);
  if (isNaN(vInt)) {
    return 0;
  } else {
    return vInt;
  }
}

export function StringToDouble(pString) {
  pString = "" + pString;
  pString = pString.replace(/,/g, "");
  //Convert sang so he so 10
  let vFloat = parseFloat(pString);
  if (isNaN(vFloat)) {
    return 0;
  } else {
    return vFloat;
  }
}

export function formatSoChungTu(stk) {
  const d = new Date();

  const yyyy = d.getFullYear();
  const mm = addZero(d.getMonth() + 1);
  const dd = addZero(d.getDate());

  return yyyy + mm + dd + stk;
}

export function formatTime() {
  const d = new Date();

  const h = addZero(d.getHours());
  const m = addZero(d.getMinutes());
  const s = addZero(d.getSeconds());

  return h + ":" + m + ":" + s;
}

export function formatDate(idata, slack = ":", _fm = "ymd") {
  let y, m, d;

  try {
    if (typeof idata === "number" || typeof idata === "string") {
      let st = new Date(idata);
      y = st.getFullYear();
      m = addZero(st.getMonth() + 1);
      d = addZero(st.getDate());
    } else {
      y = idata.getFullYear();
      m = addZero(idata.getMonth() + 1);
      d = addZero(idata.getDate());
    }
    if (_fm === "ymd") {
      return y + slack + m + slack + d;
    } else {
      return d + slack + m + slack + y;
    }
  } catch (error) {
    return null;
  }
}

export function stringToDate(idata, format = "ymd", slack = "/") {
  try {
    const _date = idata.split(slack);
    if (format === "ymd") {
      let y = _date[0];
      let m = addZero(_date[1]);
      let d = addZero(_date[2]);
      let st = new Date(y + "/" + m + "/" + d);
      return st;
    } else {
      let y = _date[2];
      let m = addZero(_date[1]);
      let d = addZero(_date[0]);
      let st = new Date(y + "/" + m + "/" + d);
      return st;
    }
  } catch (error) {
    return null;
  }
}

export function _getNameAcronym(str) {
  let matches = str.match(/\b([A-Z])/g);
  let acronym = _.takeRight(matches, 2).join("").toUpperCase();
  return acronym;
}

export function getRandom() {
  let text = "";
  let possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < 23; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

export function checkSum(a, b) {
  if (b == null || b == "" || typeof b === "undefined") {
    return "xxx";
  }
  if (typeof b == "object") {
    let checkSum = a;
    let keysCheckSum = [
      "price",
      "side",
      "volume",
      "account",
      "symbol",
      "refId",
    ];
    keysCheckSum.map((item) => {
      if (item != "volume") {
        checkSum += b[item];
      } else {
        checkSum += b[item] * 100 + "vpbs@456";
      }
    });
    return MD5(checkSum).toString();
  } else {
    return MD5(b).toString();
  }
}

export function renderParams(
  token,
  params,
  group = "Q",
  mode = "async",
  other = false
) {
  let _reParam = {},
    chkSum = "";

  _.mapKeys(params, function (value, key) {
    if (
      (key === "volume" || key === "nvol") &&
      params.cmd !== "BD.newCrossOrder"
    ) {
      _reParam[key] = StringToInt(value);
    } else {
      _reParam[key] = value;
    }
  });

  if (params.cmd === "BD.newOrder" && group === "O") {
    chkSum = checkSum(token?.sid || "", params);
  }

  console.log(chkSum);

  let _msg = {
    action: params.cmd + (other ? "Other" : ""),
    mode,
    data: {},
  };

  _msg.data["group"] = group;
  _msg.data["c"] = "H";
  _msg.data["user"] = token?.user || "";
  _msg.data["session"] = token?.sid || "";

  if (chkSum) _msg.data["checksum"] = chkSum;

  _msg.data["data"] = _reParam;

  return _msg;
}

export function daysInMonth(month, year) {
  return new Date(year, month, 0).getDate();
}

export function replaceAll(str, find, replace) {
  return str.replace(new RegExp(find, "g"), replace);
}

export function _hideMidStr(str) {
  if (!str || str.length < 2) return str;

  return str.replace(/^(\+?[\d]{2})\d+(\d{2})$/g, "$1*****$2");
}

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export function formatPhoneNumber(str) {
  //Filter only numbers from the input
  let cleaned = ("" + str).replace(/\D/g, "");
  console.log(cleaned);
  //Check if the input is of correct

  let match84 = cleaned.match(/^(84|)?(\d{3})(\d{3})(\d{3})$/);
  console.log(match84);
  if (match84) {
    //Remove the matched extension code
    //Change this to format for any country code.
    let intlCode = match84[1] ? "+84" : "";
    return [
      "(",
      intlCode,
      ") ",
      match84[2],
      ".",
      match84[3],
      ".",
      match84[4],
    ].join("");
  } else {
    let match = cleaned.match(/(\d{4})(\d{3})(\d{3})$/);
    if (match) {
      return [match[1], ".", match[2], ".", match[3]].join("");
    }
  }
  return null;
}

export function removeAccent(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  return str;
}

export function validateEmail(email) {
  var re =
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

export function _getNameEncrypt(str) {
  if (!str || str.length < 4) return str;
  if (str === "null") return "";

  let _newStr =
    str.substring(1, 2) + new Array(str.length - 4).join("*") + str.slice(-2);
  return _newStr;
}

export function isValidURL(string) {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
}

export function _diff2Date(date1, date2) {
  let mydate1, mydate2;
  if (typeof date1 === "string") {
    let parts1 = date1.split("/");
    mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === "string") {
    let parts2 = date2.split("/");
    mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor((mydate1 - mydate2) / (1000 * 60 * 60 * 24));

  return diff || 0;
}

export function _diff2DateSecond(date1, date2) {
  let mydate1, mydate2;
  if (typeof date1 === "string") {
    let parts1 = date1.split("/");
    mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);
  } else {
    mydate1 = date1;
  }
  if (typeof date2 === "string") {
    let parts2 = date2.split("/");
    mydate2 = new Date(parts2[2], parts2[1] - 1, parts2[0]);
  } else {
    mydate2 = date2;
  }

  var diff = Math.floor((mydate1 - mydate2) / 1000);

  return diff || 0;
}

export function validateCMT9(date1) {
  let parts1 = date1.split("/");
  const mydate1 = new Date(parts1[2], parts1[1] - 1, parts1[0]);

  const year1 = mydate1.getFullYear();
  const month1 = mydate1.getMonth() + 1;
  const day1 = mydate1.getDate();

  const _diffYear = new Date().getFullYear() - year1;

  if (_diffYear > 15) return false;
  if (_diffYear === 15) {
    const _month = new Date().getMonth() + 1;
    const _day = new Date().getDate();

    if (month1 > _month) return false;
    if (month1 === _month) {
      if (day1 > _day) return false;
    }
  }

  return true;
}

export function capitalizeTheFirstLetter(words) {
  if (!words) return words;

  const _newWords = words.toLowerCase();

  var separateWord = _newWords.toLowerCase().split(" ");
  for (var i = 0; i < separateWord.length; i++) {
    separateWord[i] =
      separateWord[i].charAt(0).toUpperCase() + separateWord[i].substring(1);
  }
  return separateWord.join(" ");
}

export function isDate(date) {
  if (!date) return false;
  // return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));

  console.log(stringToDate(date, "dmy"));

  const _date = stringToDate(date, "dmy");

  return _date && _date != "Invalid Date";
}

export function dataURLtoFile(dataurl, filename) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
}

export const hideMiddleDigits = (number) => {
  const visibleDigits = 2; // Số chữ số ở đầu và cuối muốn hiển thị
  const hiddenDigitsCount = number.length - visibleDigits * 2;
  const hiddenDigits = "*".repeat(hiddenDigitsCount);
  return `${number.slice(0, visibleDigits)}${hiddenDigits}${number.slice(
    -visibleDigits
  )}`;
};

export const errMsg = {
  0: { msg: "Thành công" },
  210: { msg: "Giấy tờ bị cắt góc" },
  211: { msg: "Không tìm thấy dấu quốc huy" },
  212: { msg: "Ảnh chụp giấy tờ bị loá" },
  213: { msg: "Không tìm thấy ảnh chân dung" },
  214: { msg: "Ảnh chân dung có dấu hiệu bị thay thế" },
  215: { msg: "Ảnh chụp giấy tờ là bản photo" },
  216: { msg: "Có trường thông tin bị chỉnh sửa" },
  217: { msg: "Ảnh chụp giấy tờ không phải bản gốc" },
  218: { msg: "Ảnh chụp giấy tờ nằm ngoài khung hình" },
  219: { msg: "Không tìm thấy dấu đỏ" },
  220: { msg: "Không tìm thấy vân tay phải" },
  221: { msg: "Không tìm thấy vân tay trái" },
  222: { msg: "Điểm trích xuất chính xác số CCCD thấp" },
  223: { msg: "Điểm trích xuất chính xác họ tên thấp" },
  224: { msg: "Điểm trích xuất chính xác ngày hết hạn thấp" },
  225: { msg: "Điểm trích xuất chính xác ngày hết hạn thấp" },
  226: { msg: "Loại giấy tờ mặt trước không khớp với mặt sau" },
  227: { msg: "Số giấy tờ không đúng định dạng" },
  228: { msg: "Lỗi nhận diện giấy tờ" },
  229: { msg: "Ảnh chụp không đạt yêu cầu" },
  310: { msg: "Vui lòng chuyển động gương mặt chậm" },
  311: { msg: "Không phát hiện chuyển động" },
  312: { msg: "Khuôn mặt không khớp trong quá trình liveness" },
  313: { msg: "Giả mạo xác thực" },
  314: { msg: "Vui lòng bỏ kính" },
  315: { msg: "Vui lòng bỏ khẩu trang" },
  316: { msg: "Vui lòng nhìn thẳng vào camera" },
  317: { msg: "Có nhiều khuôn mặt trong khung hình" },
  330: { msg: "Ảnh CCCD không khớp với ảnh liveness" },
  331: { msg: "Không lấy được ảnh để so sánh" },
  332: { msg: "Không tìm thấy ảnh khuôn mặt trong ảnh ID" },
  333: { msg: "Có nhiều khuôn mặt trong ảnh ID" },
  334: { msg: "Không tìm thấy ảnh khuôn mặt trong ảnh liveness" },
  335: { msg: "Có nhiều khuôn mặt trong ảnh liveness" },
  500: { msg: "Lỗi hệ thống " },
  408: { msg: "Time out" },
  1004: { msg: "Mã OTP không đúng" },
  1005: { msg: "Tài khoản CA Cloud đã bị khóa do nhập mã OTP sai quá 4 lần" },
  1013: { msg: "Chứng thư số chưa sẵn sàng" },
  1014: { msg: "Chứng thư số hết hạn" },
  1015: { msg: "Mã OTP đã hết hạn, vui lòng lấy mã OTP mới" },
  150: { msg: "CCCD hết hạn" },
  318: { msg: "Gương mặt không đầy đủ" },
};

export function getMsgByErrorCode(code) {
  console.log("code", code);
  return errMsg[code] ? errMsg[code]?.msg : "Error";
}
