import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { formValueSelector, reduxForm } from "redux-form";
import { makeGetDataAccount } from "../../../lib/selector";
import { handleApiErrors } from "../../../lib/api-errors";
import _ from "lodash";
import { setToast } from "../../client/actions";

import { dataURLtoFile } from "../../../util";
import { setDataAccount } from "../../client/actions";

import { ReactComponent as IconWarning } from "../../../assets/img/icon/ic-warning1.svg";
import { ReactComponent as IconArrow } from "../../../assets/img/icon/ic-arr-left.svg";
import ModalResAuth from "../../../components/modal/register-acc/registerAuth";
import ModalWarning from "../../../components/modal/register-acc/modalWarning";
import { getMsgByErrorCode } from "../../../util";

import { translate } from "react-i18next";
import BgFace from "../../../assets/img/bg-face.png";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormAuthFace(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const { t, handlePreSubStep, dataAccount, handleNextSubStep, prePageFirst } =
    props;

  const [streaming, setStreaming] = useState(false);

  const [faceImage, setFaceImage] = useState(null); //Lưu url ảnh khi conver sang BASE64
  const [faceLeftImage, setFaceLeftImage] = useState(null); //Lưu url ảnh khi conver sang BASE64
  const [faceRightImage, setFaceRightImage] = useState(null); //Lưu url ảnh khi conver sang BASE64

  const [fileFaceImage, setFileFaceImage] = useState(); //Lưu file ảnh -> post api
  const [fileFaceLeftImage, setFileFaceLeftImage] = useState(); //Lưu file ảnh -> post api
  const [fileFaceRightImage, setFileFaceRightImage] = useState(); //Lưu file ảnh -> post api

  const [valueImg, setValueImg] = useState(); //Lấy giá trị img trả về
  const [valueImgLeft, setValueImgLeft] = useState(); //Lấy giá trị img trả về
  const [valueImgRight, setValueImgRight] = useState(); //Lấy giá trị img trả về

  const [checkValueOcr, setCheckValueOcr] = useState(false); //Check giá trị trả về

  const [progress, setProgress] = useState(0); // % res auth
  const [checkModalWarn, setCheckModalWarn] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(false); // % process
  const [loading, setLoading] = useState(false);

  const [error, setError] = useState();
  const [txtError, setTxtError] = useState();
  const [checkSucc, setCheckSucc] = useState(false);
  const [checkVideo, setCheckVideo] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const photoRef = useRef(null);
  const btnRef = useRef(null);

  const preFaceImg = usePrevious(faceImage);
  const preFaceLeftImg = usePrevious(faceLeftImage);
  const preFaceRightImg = usePrevious(faceRightImage);

  const [images, setImages] = useState([
    {
      name: t("right-corner"),
      src: process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
    },
    {
      name: t("left-corner"),
      src: process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
    },
    {
      name: t("front-angle"),
      src: process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
    },
  ]);

  useKey("f", () => {
    if (btnRef && !loading && videoRef.current && checkVideo) {
      setIsProcessing(true);

      btnRef.current.click();

      setTimeout(() => {
        setIsProcessing(false);
      }, 500);
    }
  });

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    setImages([
      {
        name: t("right-corner"),
        src:
          faceRightImage ||
          process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
      },
      {
        name: t("left-corner"),
        src:
          faceLeftImage ||
          process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
      },
      {
        name: t("front-angle"),
        src:
          faceImage || process.env.PUBLIC_URL + `/images/res/bg-checkface.png`,
      },
    ]);
  }, [faceRightImage, faceLeftImage, faceImage]);

  useEffect(() => {
    if (faceImage && !_.isEqual(faceImage, preFaceImg))
      setFileFaceImage(dataURLtoFile(faceImage, "anhface.png"));
  }, [faceImage]);

  useEffect(() => {
    if (faceLeftImage && !_.isEqual(faceLeftImage, preFaceLeftImg))
      setFileFaceLeftImage(dataURLtoFile(faceLeftImage, "anhfacetrai.png"));
  }, [faceLeftImage]);

  useEffect(() => {
    if (faceRightImage && !_.isEqual(faceRightImage, preFaceRightImg))
      setFileFaceRightImage(dataURLtoFile(faceRightImage, "anhfacephai.png"));
  }, [faceRightImage]);

  useEffect(() => {
    if (!fileFaceImage) return;
    uploadFile(fileFaceImage, "THANG");
  }, [fileFaceImage]);

  useEffect(() => {
    if (!fileFaceLeftImage) return;
    uploadFile(fileFaceLeftImage, "TRAI");
  }, [fileFaceLeftImage]);

  useEffect(() => {
    if (!fileFaceRightImage) return;
    uploadFile(fileFaceRightImage, "PHAI");
  }, [fileFaceRightImage]);

  useEffect(() => {
    if (!checkSucc) return;
    setCheckValueOcr(true);
    setIsApiCompleted(false);
    setProgress(0); // Đảm bảo progress bắt đầu từ 0

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 90 || isApiCompleted) {
          clearInterval(interval);
          return isApiCompleted ? 100 : 90;
        }
        return prev + 1;
      });
    }, 100); // Cập nhật tiến trình mỗi 100ms

    livenessUPS();

    return () => {
      clearInterval(interval);
    };
  }, [checkSucc]);

  useEffect(() => {
    const handleCanPlay = () => {
      if (!streaming) {
        setStreaming(true);
      }
    };

    if (showViewLiveResultButton()) return;

    const video = videoRef.current;

    if (video) {
      if (window.location.protocol === "https:") {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const constraints = {
            audio: false,
            video: { facingMode: "user" },
          };

          const getFallbackStream = () => {
            const fallbackConstraints = {
              video: true, // chọn camera mặc định
              audio: false,
            };
            return navigator.mediaDevices.getUserMedia(fallbackConstraints);
          };

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              video.srcObject = stream;
              video.play();
              setTimeout(() => {
                setCheckVideo(true);
              }, 500);
            })
            .catch((err) => {
              return getFallbackStream().then((fallbackStream) => {
                video.srcObject = fallbackStream;
                video.play();
                setTimeout(() => {
                  setCheckVideo(true);
                }, 500);
              });
            })
            .catch((err) => {
              console.error(`An error occurred: ${err}`);
              setError(t("camera-unavailable"));
            });

          video.addEventListener("canplay", handleCanPlay, false);
          clearPhoto();
        } else {
          console.error(
            "getUserMedia không được hỗ trợ trong trình duyệt này."
          );
          setError(t("browser-unsafe"));
        }
      } else {
        console.error(
          "This page must be accessed over HTTPS to use the camera."
        );
        setError(t("https-required"));
      }
    }

    return () => {
      if (video) {
        const stream = video.srcObject;
        const tracks = stream?.getTracks();
        tracks?.forEach((track) => track.stop());
        video.srcObject = null;
        video.removeEventListener("canplay", handleCanPlay);
      }
    };
  }, [streaming]);

  const showViewLiveResultButton = () => {
    if (window.self !== window.top) {
      document.querySelector(".contentarea")?.remove();
      const button = document.createElement("button");
      button.textContent = "View live result of the example code above";
      document.body.appendChild(button);
      return true;
    }
    return false;
  };

  const clearPhoto = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, canvas.width, canvas.height);

        const data = canvas.toDataURL("image/png");
        if (photoRef.current) {
          photoRef.current.setAttribute("src", data);
        }
      }
    }
  };

  const takePicture = (e) => {
    e.preventDefault();
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext("2d");
      if (context) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        const data = canvas.toDataURL("image/png");
        if (!faceImage) {
          setFaceImage(data); // Lưu ảnh vào faceImage nếu chưa có
        } else if (!faceLeftImage) {
          setFaceLeftImage(data);
        } else if (!faceRightImage) {
          setFaceRightImage(data);
        }
      }
    } else {
      clearPhoto();
    }
  };

  const uploadFile = (values, type) => {
    setLoading(true);

    const formData = new FormData();
    formData.append("trucdien", values);

    const request = fetch(`${apiUrl}/uploadFile`, {
      method: "POST",
      body: formData,
      signal: abortController.signal,
    });
    handleApiUpFile(request, type);
  };

  const handleApiUpFile = (request, type) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);

        if ((json.iRs === "1" || json.iRs === 1) && json.data.trucdien) {
          if (type === "THANG") {
            setValueImg(json.data.trucdien);
          } else if (type === "TRAI") setValueImgLeft(json.data.trucdien);
          else if (type === "PHAI") setValueImgRight(json.data.trucdien);
        } else {
          _handleToast(json.sRs, "error");
        }
      })
      .catch((error) => {
        setTxtError(t("processing-error"));
        setCheckModalWarn(true);
        setLoading(false);

        throw error;
      });
  };

  const livenessUPS = () => {
    const params = {
      sid: dataAccount?.S_ID,
      anhTime1: valueImg,
      anhTime2: valueImgLeft,
      anhTime3: valueImgRight,
    };

    const request = fetch(`${apiUrl}/livenessUPS`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiLivenessUPS(request);
  };

  const handleApiLivenessUPS = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1 && json.data.exitCode === 0) {
          compareUPS();
        } else {
          _handleToast(getMsgByErrorCode(json.data.exitCode), "error");
          _handleCloseModal();
        }
      })
      .catch((error) => {
        setTxtError(t("processing-error"));
        setCheckModalWarn(true);
        setCheckSucc(false);

        throw error;
      });
  };

  const compareUPS = () => {
    const params = {
      sid: dataAccount?.S_ID,
      anhMatTruoc: dataAccount?.C_ANH_MAT_TRUOC,
      anhTime1: valueImg,
    };

    const request = fetch(`${apiUrl}/compareUPS`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiCompareUPS(request, valueImg);
  };

  const handleApiCompareUPS = (request, value) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setIsApiCompleted(true); // Đánh dấu API đã hoàn thành
        setProgress(100); // Cập nhật tiến trình lên 100%
        setCheckValueOcr(false);
        setCheckSucc(false);
        if (json.iRs === 1 && json.data.data.isMatch === "MATCH") {
          let _dataAcc = Object.assign({}, dataAccount);
          _dataAcc.C_COMPARE_RESULT = "MATCH";
          _dataAcc.C_ANH_CHAN_DUNG = value;

          dispatch(setDataAccount(_dataAcc));
          setCheckModalWarn(false);

          handleNextSubStep();
          history.push("/open-account/register-service");
        } else if (json.iRs === 1 && json.data.data.isMatch === "UNMATCH") {
          let _dataAcc = Object.assign({}, dataAccount);
          _dataAcc.C_COMPARE_RESULT = "UNMATCH";
          setTxtError(getMsgByErrorCode(json.data.exitCode));

          dispatch(setDataAccount(_dataAcc));

          setCheckModalWarn(true);
        } else {
          let _dataAcc = Object.assign({}, dataAccount);
          _dataAcc.C_COMPARE_RESULT = json.sRs;

          dispatch(setDataAccount(_dataAcc));

          _handleToast(json.data.exitCodeMessage, "error");

          _handleCloseModal();
        }
      })
      .catch((error) => {
        _handleCloseModal();
        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  const _handleCloseModal = () => {
    setCheckValueOcr(false);
    _handleTakePhoto();
  };

  const _handleCloseModalWrn = () => {
    _handleTakePhoto();
  };

  const _handleTakePhoto = () => {
    setProgress(0);
    setCheckModalWarn(false);
    setCheckSucc(false);
    setValueImg("");
    setValueImgLeft("");
    setValueImgRight("");
    setFaceImage("");
    setFaceLeftImage("");
    setFaceRightImage("");
  };

  const switchImages = () => {
    setImages((prevImages) => [prevImages[2], prevImages[0], prevImages[1]]);
  };

  const switchImagesNext = () => {
    setImages((prevImages) => [prevImages[1], prevImages[2], prevImages[0]]);
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <div className="flex flex-column  gap-4">
      <div>
        <span className="text-xs text-skin-title font-semibold">
          {t("title-face")}
          {":"}
        </span>
        <ul
          className="list-square"
          style={{
            paddingLeft: "16px",
          }}
        >
          <li className="text-xs font-medium text-skin-body">
            {t("list-title-face")}
          </li>
          <li className="text-xs font-medium text-skin-body">
            {t("list-title-face-1")}
          </li>
          <li className="text-xs font-medium text-skin-body">
            {t("list-title-face-2")}
          </li>
        </ul>
      </div>

      <div className="">
        <div className="d-flex flex-row gap-6">
          <div
            className="flex justify-center items-center"
            style={{
              width: "500px",
            }}
          >
            <div
              className={`w-100 h-100 flex flex-column items-center justify-center gap-1 rounded-2xl`}
            >
              <div
                className="d-flex flex-column justify-content-center gap-4 items-center bg-skin-natural-12 rounded-2xl"
                style={{
                  width: "100%",
                  height: "300px",
                }}
              >
                {error ? (
                  <div className="flex flex-column gap-2 items-center justify-center">
                    <IconWarning />
                    <>
                      <span className="text-base font-semibold text-skin-title text-center">
                        {error}
                      </span>
                      <span className="text-sm font-normal text-skin-body text-center">
                        {t("check-device")}
                      </span>
                    </>
                  </div>
                ) : (
                  <div
                    className="video-container-face"
                    style={{
                      borderRadius: "12px",
                    }}
                  >
                    <video
                      id="video"
                      ref={videoRef}
                      autoPlay
                      playsInline
                      muted={true}
                    ></video>
                    <div
                      className="absolute w-100 h-100 d-flex align-items-center justify-content-center"
                      style={{
                        top: "0px",
                        backgroundImage: `url(${BgFace})`,

                        backgroundSize: "cover",
                        backgroundPosition: "center",
                      }}
                    ></div>
                    {!checkVideo && (
                      <div className="loader text-lg font-semibold text-skin-title text-center"></div>
                    )}
                  </div>
                )}
              </div>
              {!error && (
                <>
                  <span className="font-semibold text-skin-title text-center text-lg">
                    {!faceImage
                      ? t("face-center")
                      : !faceLeftImage
                      ? t("turn-left")
                      : !faceRightImage
                      ? t("turn-right")
                      : ""}
                  </span>
                  <div className="camera flex flex-column justify-content-center items-center">
                    {!faceRightImage || !faceLeftImage || !faceImage ? (
                      <button
                        id="startbutton"
                        onClick={takePicture}
                        className="bg-skin-purple text-skin-title border-none"
                        style={{
                          padding: "0px 16px",
                          borderRadius: "1000px",
                          height: "40px",
                        }}
                        ref={btnRef}
                      >
                        {t("take-img")}
                      </button>
                    ) : (
                      <button
                        id="startbutton"
                        onClick={_handleTakePhoto}
                        className="bg-skin-inversewhite text-skin-title border-none"
                        style={{
                          padding: "0px 16px",
                          borderRadius: "1000px",
                          height: "40px",
                        }}
                      >
                        {t("take-img-l")}
                      </button>
                    )}

                    <canvas
                      className="hidden w-100 h-100"
                      id="canvas"
                      ref={canvasRef}
                    ></canvas>
                  </div>
                  {(!faceRightImage || !faceLeftImage || !faceImage) && (
                    <span className="text-sm text-skin-subdued font-medium">
                      {t("or-input")} {' "'}
                      <span className="text-skin-title">F</span>
                      {'"'} {t("to-take-img")}
                    </span>
                  )}
                </>
              )}
            </div>
          </div>
          <div>
            <span className="mb-1">
              {!faceImage
                ? t("center")
                : !faceLeftImage
                ? t("left-corner")
                : !faceRightImage
                ? t("right-corner")
                : images[0]?.name}
            </span>
            <div className="position-relative">
              <div
                className={`position-absolute rounded-xl border p-1 flex items-center justify-center bg-skin-body ${
                  faceRightImage ? "border-skin-weak" : " border-skin-green"
                }`}
                style={{
                  width: "105px",
                  height: "158px",
                  zIndex: 3,
                }}
              >
                <img
                  src={images[0]?.src}
                  alt="Ảnh mặt phải"
                  className="rounded-xl object-cover w-100 h-100"
                />
              </div>
              <div
                className="p-1 position-absolute rounded-xl border border-skin-weak border-dashed flex items-center justify-center"
                style={{
                  width: "87px",
                  height: "130px",
                  left: "40px",
                  top: "14px",
                  zIndex: 2,
                }}
              >
                <img
                  src={images[1]?.src}
                  alt="Ảnh mặt trái"
                  className="rounded-xl object-cover w-100 h-100"
                />
              </div>
              <div
                className="p-1 position-absolute rounded-xl border border-skin-weak border-dashed flex items-center justify-center"
                style={{
                  width: "69px",
                  height: "102px",
                  top: "27px",
                  left: "77px",
                  zIndex: 1,
                }}
              >
                <img
                  src={images[2]?.src}
                  alt="Ảnh mặt trước"
                  className="rounded-xl object-cover w-100 h-100"
                />
              </div>
            </div>
            {faceRightImage && faceLeftImage && faceImage && (
              <div
                className="d-flex flex-row gap-3"
                style={{
                  position: "relative",
                  top: "10.5rem",
                  left: "2rem",
                }}
              >
                <IconArrow onClick={switchImages} />
                <IconArrow
                  style={{
                    transform: "rotate(180deg)",
                  }}
                  onClick={switchImagesNext}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className="col-span-2 flex flex-column gap-3 "
        style={{
          height: "max-content",
          padding: "8px 12px",
          maxWidth: "450px",
        }}
      >
        <div className="flex flex-column gap-3">
          <div className="d-flex flex-row align-items-center justify-content-between">
            <div className="flex flex-column items-center justify-center gap-2 ">
              <img
                src={process.env.PUBLIC_URL + `/images/res/img-km.png`}
                style={{
                  width: "40px",
                  height: "45px",
                }}
                alt="Ảnh quá tối hoặc quá sáng"
              />
              <span className="text-xs font-medium text-skin-title text-center">
                {t("too-dark-or-bright")}
              </span>
            </div>
            <div className="flex flex-column items-center justify-center gap-2 ">
              <img
                src={process.env.PUBLIC_URL + `/images/res/img-kcm.png`}
                style={{
                  width: "40px",
                  height: "45px",
                }}
                alt="Ảnh khuôn mặt bị che"
              />
              <span className="text-xs font-medium text-skin-title text-center">
                {t("face-covered")}
              </span>
            </div>
            <div className="flex flex-column items-center justify-center gap-2 ">
              <img
                src={process.env.PUBLIC_URL + `/images/res/img-knn.png`}
                style={{
                  width: "40px",
                  height: "45px",
                }}
                alt="Ảnh nhiều hơn một người"
              />
              <span className="text-xs font-medium text-skin-title text-center">
                {t("more-than-one-person")}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row gap-2 pt-6 ">
        <button className="btn-close" onClick={() => handlePreSubStep()}>
          {t("pre")}
        </button>
        {valueImg && valueImgLeft && valueImgRight && (
          <button
            className="btn-submit success"
            onClick={() => {
              setCheckSucc(true);
            }}
          >
            {t("continue-step")}
          </button>
        )}
      </div>
      {checkValueOcr && (
        <ModalResAuth
          handleCloseModal={_handleCloseModal}
          progress={progress}
          titleText={t("auth-face")}
          detailText={t("please-wait-face")}
        />
      )}
      {checkModalWarn && (
        <ModalWarning
          handleCloseModal={_handleCloseModalWrn}
          txtError={txtError}
        />
      )}
      {loading && <div className="loader-full"></div>}
    </div>
  );
}

FormAuthFace = reduxForm({
  form: "formResStep15",
  enableReinitialize: true,
})(FormAuthFace);

const selector = formValueSelector("formResStep15");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  const { formMobile, formEmail, formReferralCode, formNamePre } = selector(
    state,
    "formMobile",
    "formEmail",
    "formReferralCode",
    "formNamePre"
  );

  return {
    dataAccount: getDataAccount(state),

    formMobile,
    formEmail,
    formReferralCode,
    formNamePre,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormAuthFace)
);

function useKey(key, cb) {
  const callback = useRef(cb);

  useEffect(() => {
    callback.current = cb;
  });

  useEffect(() => {
    function handle(event) {
      if (event.code === key) {
        callback.current(event);
      } else if (
        key === "w" &&
        (event.keyCode === 231 || event.keyCode === 87)
      ) {
        //w/W/ư ====> bán
        callback.current(event);
      } else if (key === "q" && event.keyCode === 81) {
        //q/Q ====> mua
        callback.current(event);
      } else if (key === "f" && event.keyCode === 70) {
        //f/F ====> submit form
        callback.current(event);
      } else if (key === "ctrls" && event.keyCode === 83 && event.ctrlKey) {
        event.preventDefault();
        callback.current(event);
      } else if (key === "ctrlx" && event.keyCode === 88 && event.ctrlKey) {
        callback.current(event);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}
