import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { change, Field, formValueSelector, reduxForm } from "redux-form";
import { makeGetDataAccount } from "../../../lib/selector";

import RenderInput from "../../../components/input/renderInputLogin";
import RenderDatePicker from "../../../components/input/renderDatePicker";
import RenderNormalSelect from "../../../components/input/renderNormalSelect";
import { handleApiErrors } from "../../../lib/api-errors";
import _ from "lodash";
import { setToast } from "../../client/actions";

import { required, validateBirth } from "../../../util/validation";
import { setDataAccount } from "../../client/actions";

import { translate } from "react-i18next";

const genders = [
  {
    value: "NAM",
    label: "Nam",
  },
  {
    value: "NỮ",
    label: "Nữ",
  },
];

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormAddInfor(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handleSubmit,
    submitting,
    handleNextSubStep,
    handlePreSubStep,
    formIdentification,
    dataAccount,
    orcData,
    prePageFirst,
    formBirthDay,
    t,
  } = props;
  const [checkCard, setCheckCard] = useState();
  const [listCity, setListCity] = useState();
  const [optsProvince, setOptsProvince] = useState([]); // Tỉnh/ Thành phố

  const preDataAccount = usePrevious(dataAccount);
  const preFormIdentification = usePrevious(formIdentification);
  const preListCity = usePrevious(listCity);

  useEffect(() => {
    getCity();
    innitForm();

    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (dataAccount && !_.isEqual(dataAccount, preDataAccount)) {
      innitForm();
    }
  }, [dataAccount]);

  useEffect(() => {
    if (listCity && listCity.length > 0) {
      dispatch(change("formResStep14", "formCity", dataAccount?.C_PROVINCE));
    }
  }, [listCity]);

  useEffect(() => {
    if (listCity && !_.isEqual(listCity, preListCity)) {
      let _optsProvince = [];
      // _optsProvince.push({ value: '', label: '-- Chọn tỉnh thành --' });
      listCity.forEach((element) => {
        _optsProvince.push({ value: element.C_CODE, label: element.C_NAME });
      });
      setOptsProvince(_optsProvince);
    }
  }, [listCity]);

  const innitForm = () => {
    if (!dataAccount || !orcData) return;
    if (dataAccount && dataAccount?.C_EDIT_FLAG === "1") {
      dispatch(
        change("formResStep14", "formFullName", dataAccount?.C_FULL_NAME)
      );
      dispatch(
        change("formResStep14", "formBirthDay", dataAccount?.C_CUST_BIRTH_DAY)
      );
      dispatch(change("formResStep14", "formGender", dataAccount?.C_GENDER));
      dispatch(
        change("formResStep14", "formIdentification", dataAccount?.C_CARD_ID)
      );
      dispatch(
        change("formResStep14", "formDueDate", dataAccount?.C_ISSUE_DATE)
      );
      dispatch(
        change("formResStep14", "formExpDate", dataAccount?.C_ISSUE_EXPIRE)
      );
      dispatch(
        change("formResStep14", "formIssueBy", dataAccount?.C_ISSUE_PLACE)
      );
      dispatch(
        change("formResStep14", "formReAddress", dataAccount?.C_ADDRESS)
      );

      dispatch(
        change("formResStep14", "formAddressLh", dataAccount?.C_CONTACT_ADDRESS)
      );
    } else {
      dispatch(change("formResStep14", "formFullName", orcData.data.fullName));
      dispatch(
        change("formResStep14", "formBirthDay", orcData.data.dateOfBirth)
      );
      dispatch(change("formResStep14", "formGender", orcData.data.gender));
      dispatch(
        change("formResStep14", "formIdentification", orcData.data.idNumber)
      );
      dispatch(change("formResStep14", "formDueDate", orcData.data.issuedDate));
      dispatch(
        change("formResStep14", "formExpDate", orcData.data.expiredDate)
      );
      dispatch(
        change("formResStep14", "formIssueBy", orcData.data.issuedPlace)
      );
      dispatch(change("formResStep14", "formReAddress", orcData.data.address));

      dispatch(change("formResStep14", "formAddressLh", orcData.data.address));
    }
  };

  const getCity = () => {
    const params = {
      user: "back",
      cmd: "LIST_PROVINCE",
      param: {},
      sid: dataAccount?.S_ID,
    };

    const request = fetch(`${apiUrl}/listProvince`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiGetCity(request);
  };

  const handleApiGetCity = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          setListCity(json.data);
        } else {
          console.error(json.sRs);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const checkCardId = (values) => {
    const params = {
      user: "back",
      cmd: "CHECK_CARDID",
      param: {
        C_CARD_ID: values?.formIdentification + "",
        C_ACCOUNT_NAME: values?.formFullName + "",
        C_BIRTH_DAY: values?.formBirthDay + "",
      },
      sid: dataAccount?.S_ID,
    };

    const request = fetch(`${apiUrl}/checkCardId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiCardId(request);
  };

  const handleApiCardId = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs > 0) {
          handleNextSubStep();
        } else {
          _handleToast(json.sRs, "error");
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        throw error;
      });
  };

  function submit(values) {
    const {
      formFullName,
      formBirthDay,
      formGender,
      formIdentification,
      formDueDate,
      formExpDate,
      formIssueBy,
      formReAddress,
      formAddressLh,
    } = values;

    let _dataAcc = Object.assign({}, dataAccount);
    // _dataAcc.C_DATA_CCCD = json.data;
    _dataAcc.C_CARD_ID = formIdentification;
    _dataAcc.C_FULL_NAME = formFullName;
    _dataAcc.C_CUST_BIRTH_DAY = formBirthDay;
    _dataAcc.C_ISSUE_DATE = formDueDate;
    _dataAcc.C_ISSUE_EXPIRE = formExpDate;
    _dataAcc.C_ISSUE_PLACE = formIssueBy;
    _dataAcc.C_GENDER = formGender;
    _dataAcc.C_ADDRESS = formReAddress;
    _dataAcc.C_CONTACT_ADDRESS = formAddressLh;
    _dataAcc.C_NATIONALITY = orcData.data.nationality;
    if (
      formBirthDay !== orcData.data.dateOfBirth ||
      formDueDate !== orcData.data.issuedDate ||
      formExpDate !== orcData.data.expiredDate
    ) {
      _dataAcc.C_EDIT_FLAG = "1";
    } else {
      _dataAcc.C_EDIT_FLAG = "0";
    }

    dispatch(setDataAccount(_dataAcc));
    checkCardId(values);
    // handleNextSubStep();
  }

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  return (
    <form
      onSubmit={handleSubmit(submit)}
      className=" flex flex-column gap-4 form-group"
    >
      <div className="flex flex-column gap-4">
        <div className="flex flex-column">
          <span className="text-xs font-medium text-skin-body">
            {t("detail-title-3")}
          </span>
        </div>
        <div className="grid grid-cols-6 gap-4 items-baseline">
          <div className="grid-res-26 grid grid-rows-1">
            <label className="text-xs text-skin-disable mb-1">
              {t("full-name")}
            </label>
            <Field
              name="formFullName"
              type="text"
              placeholder={t("full-name")}
              component={RenderInput}
              className="w-100 px-4 py-3"
              validate={required}
              disabled
            />
          </div>
          <div className="grid-res-26 grid grid-rows-1">
            <label className="text-xs  mb-1">
              {t("dob")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formBirthDay"
              component={RenderDatePicker}
              className="w-100 px-4 py-3 text-sm"
              validate={[required, validateBirth]}
            />
          </div>
          <div className="grid-res-26 grid grid-rows-1 ">
            <label className="text-xs  mb-1">
              {t("gender")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formGender"
              placeholderText={t("gender-choise")}
              component={RenderNormalSelect}
              className="w-100 px-4 py-3 "
              validate={required}
              opts={genders}
            />
          </div>
          <div className="grid-res-26 grid grid-rows-1">
            <label className="text-xs text-skin-disable mb-1">
              {t("card-id")}
            </label>
            <Field
              name="formIdentification"
              type="text"
              component={RenderInput}
              className="w-100 px-4 py-3 "
              disabled
              validate={required}
            />
            {checkCard && (
              <small className="text-left text-sm text-skin-sell">
                {checkCard}
              </small>
            )}
          </div>
          <div className="grid-res-26 grid grid-rows-1">
            <label className="text-xs mb-1 ">
              {t("issue-date")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formDueDate"
              component={RenderDatePicker}
              className="w-100 px-4 py-3 text-sm"
              validate={[required, validateBirth]}
            />
          </div>
          <div className="grid-res-26 grid grid-rows-1">
            <label className="text-xs mb-1">
              {t("expiry-date")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formExpDate"
              component={RenderDatePicker}
              className="w-100 px-4 py-3 text-sm"
              validate={[required, validateBirth]}
            />
          </div>
          <div className="col-span-6 grid grid-rows-1">
            <label className="text-xs mb-1 ">
              {t("place-issued")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formIssueBy"
              type="text"
              component={RenderInput}
              className="w-100 px-4 py-3 text-sm"
              validate={required}
            />
          </div>
          <div className="col-span-6 grid grid-rows-1">
            <label className="text-xs mb-1 ">
              {t("re-address")} <span className="text-danger">*</span>
            </label>
            <Field
              name="formReAddress"
              type="text"
              component={RenderInput}
              className="w-100 px-4 py-3 text-sm"
              validate={required}
            />
          </div>
        </div>
      </div>
      <div className="flex flex-column gap-4">
        <div className="grid grid-cols-6 gap-6 items-baseline">
          <div className="col-span-6 grid grid-rows-1">
            <label className="text-xs mb-1 ">
              {t("con-address")} <span className="text-danger">*</span>
            </label>

            <Field
              name="formAddressLh"
              type="text"
              component={RenderInput}
              className="w-100 px-4 py-3 text-sm"
              validate={required}
            />
          </div>
        </div>
      </div>

      <div
        className="flex flex-row-reverse justify-content-start gap-2  w-button"
        style={{
          paddingTop: "12px",
        }}
      >
        <button
          type="submit"
          disabled={submitting || checkCard}
          className="btn-submit success"
        >
          {t("continue-step")}
        </button>{" "}
        <button
          className="btn-close"
          onClick={() => {
            handlePreSubStep();
          }}
        >
          {t("pre")}
        </button>
      </div>
    </form>
  );
}

FormAddInfor = reduxForm({
  form: "formResStep14",
  enableReinitialize: true,
})(FormAddInfor);

const selector = formValueSelector("formResStep14");

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  const {
    formFullName,
    formBirthDay,
    formGender,
    formIdentification,
    formDueDate,
    formExpDate,
    formIssueBy,
    formReAddress,
    formPhoneNumber,
    formEmail,
    formConCode,
    formConName,
    formAddressLh,
    formCity,
  } = selector(
    state,
    "formFullName",
    "formBirthDay",
    "formGender",
    "formIdentification",
    "formDueDate",
    "formExpDate",
    "formIssueBy",
    "formReAddress",
    "formAddressLh"
  );

  return {
    dataAccount: getDataAccount(state),

    formFullName,
    formBirthDay,
    formGender,
    formIdentification,
    formDueDate,
    formExpDate,
    formIssueBy,
    formReAddress,
    formAddressLh,
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormAddInfor)
);
