import React, { useEffect, useState, useRef } from "react";

import { connect, useDispatch } from "react-redux";

import PageBaseInfor from "./pageBaseInfor";
import PageResService from "./pageResService";
import PageSignCont from "./pageSignCont";

import { makeGetDataAccount, makeGetSetTimeSs } from "../../lib/selector";
import { useHistory } from "react-router";
import { handleApiErrors } from "../../lib/api-errors";

import BgTextRegister from "../../assets/img/bg/authen/txt-register.svg";
import { ReactComponent as IconChecked } from "../../assets/img/icon/icChecked.svg";
import bgGridCd from "../../util/bg-step-grid.json";
import Lottie from "lottie-react";
import * as _ from "lodash";
import { setDataAccount, setSaveTimeSS, setStepRes } from "../client/actions";
import { translate } from "react-i18next";

import ModalWarning from "../../components/modal/register-acc/modalWarning";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function RegisterAccount(props) {
  const {
    match: { params },
    dataAccount,
    t,
    setTimeSS,
  } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const [subStep, setSubStep] = useState(1);
  const [orcData, setOrcData] = useState(null);

  const preSubStep = usePrevious(subStep);
  const preDataAccount = usePrevious(dataAccount);

  const [isOpenAccSS, setIsOpenAccSS] = useState(false);
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (subStep < 2 || isOpenAccSS) return;
    const delay = 30 * 60 * 1000;
    let timeoutId;

    const checkPopup = () => {
      const elapsed = Date.now() - setTimeSS;
      const remainingTime = delay - elapsed;

      if (remainingTime > 0) {
        timeoutId = setTimeout(() => {
          setShowPopup(true);
        }, remainingTime);
      } else {
        setShowPopup(true);
      }
    };

    checkPopup();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId); // Clear the timeout
      }
    };
  }, [subStep, isOpenAccSS]);

  console.log("showPopup", showPopup, setTimeSS);

  useEffect(() => {
    if (
      dataAccount &&
      subStep > preSubStep &&
      !_.isEqual(subStep, preSubStep)
    ) {
      pushLog(dataAccount);
    }
    if (subStep > 0) {
      dispatch(setStepRes(subStep));
    }
  }, [subStep, dataAccount]);

  const handleNextSubStep = () => {
    setSubStep((pre) => pre + 1);
  };

  const handlePreSubStep = () => {
    setSubStep((pre) => pre - 1);
  };

  // quay lại làm từ bước đầu nếu ss hết hạn
  const prePageFirst = () => {
    setSubStep(1);
  };

  const pushLog = (values) => {
    if (!values) return;
    const params = {
      C_UUID: values?.S_ID, //session
      C_EMAIL: values?.C_EMAIL, //Email
      C_PHONE: values?.C_MOBILE, //SĐT
      C_STEP: subStep, //Các bước thực hiện
      C_STATUS: "",
      C_UI_ERR: "",
      C_CODE_ERR: "",
      C_PARAMS_INPUT: values,
      C_OS: "",
      C_OS_VERSION: "",
      C_PHONE_TYPE: "", //Thiết bị
      C_CLIENT_IP: "", //IP
      C_TIME: new Date().toLocaleString(), //Thời gian
      C_OCR_RESULT: values?.C_OCR_RESULT, // Lỗi từ exitCodeMessage
      C_COMPARE_RESULT: values?.C_COMPARE_RESULT, //Lỗi từ compare
      C_SMS_RESULT: "", //Lỗi từ Check SMS_OTP
      C_ACCOUNT_LOCK_INFO: values?.C_ACCOUNT, //STK đang hold
      C_SIGN_RESULT: "", //Kết quả ký hợp đồng
    };

    const request = fetch(`${apiUrl}/logMobile`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiPushLog(request);
  };

  const handleApiPushLog = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        if (json.iRs === 1) {
          console.log("success");
        } else {
          console.error(json.sRs);
        }
      })
      .catch((error) => {
        throw error;
      });
  };

  const handleGetOrcData = (data) => {
    setOrcData(data);
  };

  const handleCheckSS = () => {
    history.push("/open-account/check-info");
    prePageFirst();
    dispatch(setDataAccount(null));
    dispatch(setSaveTimeSS(null));
    setShowPopup(false);
  };

  return (
    <div className="grid-container ">
      <div className="header-login font-briGro w-100 overflow-hidden ">
        <div className="header-login_title">
          <div className="text-skin-subdued text-base mt-2">
            {t("register")}
          </div>
          <img
            src={BgTextRegister}
            alt=""
            style={{
              height: "48px",
            }}
          />
        </div>

        <div className="header-login__top">
          <div
            className="flex items-center bg-step-top "
            style={{
              position: "relative",
              zIndex: "99",
            }}
          >
            <div className="ma-left-check text-skin-text3 font-hanGro md:my-6 text-lg step-top-title">
              <div
                className={`relative ${
                  params?.typeId === "check-info"
                    ? "step-active"
                    : "step-un-active"
                }`}
              >
                <div
                  className={
                    "step-top-detail border border-skin-weak bg-skin-sur-solid flex items-center relative rounded-sm " +
                    (params?.typeId === "check-info"
                      ? "text-skin-green text-base font-semibold font-briGro active-step"
                      : "opacity-50 not-active-step text-sm desktop-only")
                  }
                >
                  {t("infor-basic")}
                  <div className="po-check bg-skin-primary rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                    <div className="bg-shadow bg-skin-natural-6 rounded-full"></div>
                  </div>
                </div>
                <div className="po-check rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                  <div className="po-check-num bg-skin-natural-7 rounded-full text-skin-white text-lg flex items-center justify-center">
                    {params?.typeId !== "check-info" ? (
                      <div className="w-icon-check rounded-full bg-skin-active flex items-center justify-center">
                        <IconChecked className="opacity-75" />
                      </div>
                    ) : (
                      1
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`relative box-top_margin-step ${
                  params?.typeId === "register-service"
                    ? "step-active"
                    : "step-un-active"
                }`}
              >
                <div
                  className={
                    "step-top-detail border border-skin-weak bg-skin-sur-solid flex items-center relative rounded-sm " +
                    (params?.typeId === "register-service"
                      ? "text-skin-green text-base font-semibold font-briGro active-step"
                      : "opacity-50 not-active-step text-sm desktop-only")
                  }
                >
                  {t("res-service")}
                  <div className="po-check bg-skin-primary rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                    <div className="bg-shadow bg-skin-natural-6 rounded-full"></div>
                  </div>
                </div>
                <div className="po-check rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                  <div className="po-check-num bg-skin-natural-7 rounded-full text-skin-white text-lg flex items-center justify-center">
                    {params?.typeId === "sign-contract" ? (
                      <div className="w-icon-check rounded-full bg-skin-active flex items-center justify-center">
                        <IconChecked className="opacity-75" />
                      </div>
                    ) : (
                      2
                    )}
                  </div>
                </div>
              </div>
              <div
                className={`relative box-top_margin-step ${
                  params?.typeId === "sign-contract"
                    ? "step-active"
                    : "step-un-active"
                }`}
                style={{}}
              >
                <div
                  className={
                    "step-top-detail border border-skin-weak bg-skin-sur-solid flex items-center relative rounded-sm " +
                    (params?.typeId === "sign-contract"
                      ? "text-skin-green text-base font-semibold font-briGro active-step"
                      : "opacity-50 not-active-step text-sm desktop-only")
                  }
                >
                  {t("sign-contract")}
                  <div className="po-check  bg-skin-primary rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                    <div className="w-[34px] h-[34px] bg-skin-natural-6 rounded-full"></div>
                  </div>
                </div>
                <div className="po-check  rounded-full absolute -translate-y-1/2 flex items-center justify-center">
                  <div className="po-check-num bg-skin-natural-7 rounded-full text-skin-white text-lg flex items-center justify-center">
                    3
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Lottie
          className="bg-grid-cd desktop-only"
          animationData={bgGridCd}
          loop={true}
        />
      </div>
      <div className="grid gap-4">
        {params?.typeId === "check-info" && (
          <PageBaseInfor
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
            handleGetOrcData={handleGetOrcData}
            prePageFirst={prePageFirst}
            subStep={subStep}
            orcData={orcData}
          />
        )}
        {params?.typeId === "register-service" && (
          <PageResService
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
            prePageFirst={prePageFirst}
            subStep={subStep}
            orcData={orcData}
          />
        )}
        {params?.typeId === "sign-contract" && (
          <PageSignCont
            handlePreSubStep={handlePreSubStep}
            handleNextSubStep={handleNextSubStep}
            prePageFirst={prePageFirst}
            subStep={subStep}
            orcData={orcData}
            handleStopTime={() => setIsOpenAccSS(true)}
          />
        )}

        {showPopup && (
          <ModalWarning
            handleCloseModal={handleCheckSS}
            txtError={t("session-expired")}
            type="checkSS"
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();
  const getSetTimeSs = makeGetSetTimeSs();

  return {
    dataAccount: getDataAccount(state),
    setTimeSS: getSetTimeSs(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(RegisterAccount)
);
