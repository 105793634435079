export const DATA_ACCOUNT_SET = "DATA_ACCOUNT_SET";
export const SAVE_LIST_ACC_HOLD = "SAVE_LIST_ACC_HOLD";
export const SET_STEP_RES = "SET_STEP_RES";
export const SAVE_TIME_SS = "SAVE_TIME_SS";

export const CHECK_ACC_REQUESTING = "CHECK_ACC_REQUESTING";
export const CHECK_ACC_REQUEST_SUCCESS = "CHECK_ACC_REQUEST_SUCCESS";
export const CHECK_ACC_REQUEST_ERROR = "CHECK_ACC_REQUEST_ERROR";

export const PUSH_LOG_REQUESTING = "PUSH_LOG_REQUESTING";
export const PUSH_LOG_REQUEST_SUCCESS = "PUSH_LOG_REQUEST_SUCCESS";
export const PUSH_LOG_REQUEST_ERROR = "PUSH_LOG_REQUEST_ERROR";
