import { createSelector } from "reselect";

import * as _ from "lodash";

const getToast = (state) => state.client.toast;
const getDataAccount = (state) => state.client.dataAccount;
const getListAccHold = (state) => state.client.listAccHold;
const getSetStepRes = (state) => state.client.setStepRes;
const getSetTimeSs = (state) => state.client.setTimeSs;

/*****************************************  MAKE GET *************************************************/

export const makeGetToast = () => createSelector([getToast], (toast) => toast);
export const makeGetDataAccount = () =>
  createSelector([getDataAccount], (checkAccount) => checkAccount);
export const makeGetListAccHold = () =>
  createSelector([getListAccHold], (listAccHold) => listAccHold);
export const makeGetSetStepRes = () =>
  createSelector([getSetStepRes], (setStepRes) => setStepRes);
export const makeGetSetTimeSs = () =>
  createSelector([getSetTimeSs], (setTimeSs) => setTimeSs);
