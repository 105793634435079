import { call, put, takeLatest, all } from "redux-saga/effects";
import { handleApiErrors } from "../../lib/api-errors";
import { CHECK_ACC_REQUESTING, PUSH_LOG_REQUESTING } from "./constants";

import {
  checkAccountRequestSuccess,
  checkAccountRequestError,
  pushLogError,
  pushLogSuccess,
} from "./actions";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function handleRequest(request) {
  return request
    .then(handleApiErrors)
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => {
      throw error;
    });
}

function checkAccountRequestApi(data) {
  const url = `${apiUrl}/verifyPhoneEmail`;
  const request = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function pushLogRequestApi(data) {
  const url = `${apiUrl}/logMobile`;
  const request = fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded;",
    },
    body: JSON.stringify(data),
  });
  return handleRequest(request);
}

function* checkAccountRequestFlow({ data }) {
  try {
    const data = yield call(checkAccountRequestApi, data);
    console.log("checkAccountRequestFlow", data);
    yield put(checkAccountRequestSuccess(data));
  } catch (error) {
    // console.log('checkAccountRequestFlow', error)
    yield put(checkAccountRequestError(error));
  }
}

function* pushLogRequestFlow({ data }) {
  try {
    const data = yield call(pushLogRequestApi, data);
    yield put(pushLogSuccess(data));
  } catch (error) {
    // console.log('pushLogRequestFlow', error)
    yield put(pushLogError(error));
  }
}

function* clientWatcher() {
  yield all([takeLatest(CHECK_ACC_REQUESTING, checkAccountRequestFlow)]);
  yield all([takeLatest(PUSH_LOG_REQUESTING, pushLogRequestFlow)]);
}

export default clientWatcher;
