import React, { useEffect, useState, useRef } from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { makeGetDataAccount } from "../../../lib/selector";
import { handleApiErrors } from "../../../lib/api-errors";
import PerfectScrollbar from "react-perfect-scrollbar";
import _ from "lodash";
import { replaceAll, dataURLtoFile } from "../../../util";
import { setDataAccount } from "../../client/actions";
import { setToast } from "../../client/actions";
import { ReactComponent as IconWarning } from "../../../assets/img/icon/ic-warning1.svg";
import { ReactComponent as IconVector } from "../../../assets/img/icon/ic-vector.svg";
import ModalResAuth from "../../../components/modal/register-acc/registerAuth";
import { getMsgByErrorCode } from "../../../util";
import { translate } from "react-i18next";

const apiUrl = `${process.env.REACT_APP_API_URL}`;

function usePrevious(value) {
  const ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FormAuthDocs(props) {
  const history = useHistory();
  const dispatch = useDispatch();
  const abortController = new AbortController();

  const {
    handleNextSubStep,
    handlePreSubStep,
    sid,
    handleGetValue,
    dataAccount,
    handleGetOrcData,
    prePageFirst,
    t,
  } = props;

  const [streaming, setStreaming] = useState(false);
  const [frontImage, setFrontImage] = useState(null); //Lưu url ảnh sau khi conver sang BASE64
  const [backImage, setBackImage] = useState(null); //Lưu url ảnh sau khi conver sang BASE64
  const [fileFrontImage, setFileFrontImage] = useState(); //Lưu file ảnh trước -> post api
  const [fileBackImage, setFileBackImage] = useState(); //Lưu file ảnh sau -> post api
  const [value, setValue] = useState(); //Lưu value trả về
  const [valueImgT, setValueImgT] = useState(); //Lấy giá trị img mặt trước trả về
  const [valueImgS, setValueImgS] = useState(); //Lấy giá trị img mặt sau trả về

  const [progress, setProgress] = useState(0); // % res auth

  const [checkValueOcr, setCheckValueOcr] = useState(false); //Check giá trị trả về
  const [checkImgVal, setCheckImgVal] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isApiCompleted, setIsApiCompleted] = useState(false); // % process

  const [error, setError] = useState("");
  const [txtError, setTxtError] = useState("");

  const [checkSucc, setCheckSucc] = useState(false);
  const [checkVideo, setCheckVideo] = useState(false);

  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const photoRef = useRef(null);
  const btnRef = useRef(null);

  const preFrontImg = usePrevious(frontImage);
  const preBackImg = usePrevious(backImage);

  const [isProcessing, setIsProcessing] = useState(false);

  useKey("f", () => {
    if (
      btnRef.current &&
      !loading &&
      checkVideo &&
      !error &&
      videoRef.current &&
      !isProcessing
    ) {
      setIsProcessing(true);

      btnRef.current.click();

      setTimeout(() => {
        setIsProcessing(false);
      }, 500);
    }
  });

  useEffect(() => {
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (frontImage && !_.isEqual(frontImage, preFrontImg))
      setFileFrontImage(dataURLtoFile(frontImage, "anhmattruoc.png"));
  }, [frontImage]);

  useEffect(() => {
    if (backImage && !_.isEqual(backImage, preBackImg))
      setFileBackImage(dataURLtoFile(backImage, "anhmatsau.png"));
  }, [backImage]);

  useEffect(() => {
    if (!checkSucc) return;
    setCheckValueOcr(true);
    setIsApiCompleted(false);
    setProgress(0); // Đảm bảo progress bắt đầu từ 0

    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 90 || isApiCompleted) {
          clearInterval(interval);
          return isApiCompleted ? 100 : 90;
        }
        return prev + 1;
      });
    }, 100); // Cập nhật tiến trình mỗi 100ms

    getOcrUPS();

    return () => {
      clearInterval(interval);
    };
  }, [checkSucc]);

  useEffect(() => {
    if (!fileFrontImage) return;
    uploadFile(fileFrontImage, "TRUOC");
  }, [fileFrontImage]);

  useEffect(() => {
    if (!fileBackImage) return;
    uploadFile(fileBackImage, "SAU");
  }, [fileBackImage]);

  useEffect(() => {
    const handleCanPlay = () => {
      if (!streaming) {
        setStreaming(true);
      }
    };

    if (showViewLiveResultButton()) return;

    const video = videoRef.current;

    if (video) {
      if (window.location.protocol === "https:") {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
          const constraints = {
            audio: false,
            video: {
              facingMode: { exact: "environment" },
            },
          };

          const getFallbackStream = () => {
            const fallbackConstraints = {
              video: true, // chọn camera mặc định
              audio: false,
            };
            return navigator.mediaDevices.getUserMedia(fallbackConstraints);
          };

          navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
              video.srcObject = stream;
              video.play();
              setTimeout(() => {
                setCheckVideo(true);
              }, 500);
            })
            .catch((err) => {
              return getFallbackStream().then((fallbackStream) => {
                video.srcObject = fallbackStream;
                video.play();
                setTimeout(() => {
                  setCheckVideo(true);
                }, 500);
              });
            })
            .catch((err) => {
              console.error(`An error occurred: ${err}`);
              setError(t("camera-unavailable"));
            });

          video.addEventListener("canplay", handleCanPlay, false);
          clearPhoto();
        } else {
          console.error(
            "getUserMedia không được hỗ trợ trong trình duyệt này."
          );
          setError(t("browser-unsafe"));
        }
      } else {
        console.error(
          "This page must be accessed over HTTPS to use the camera."
        );
        setError(t("https-required"));
      }
    }

    return () => {
      if (video) {
        const stream = video.srcObject;
        const tracks = stream?.getTracks();
        tracks?.forEach((track) => track.stop());
        video.srcObject = null;
        video.removeEventListener("canplay", handleCanPlay);
      }
    };
  }, [streaming, frontImage, backImage]);

  const showViewLiveResultButton = () => {
    if (window.self !== window.top) {
      document.querySelector(".contentarea")?.remove();
      const button = document.createElement("button");
      button.textContent = "View live result of the example code above";
      document.body.appendChild(button);
      return true;
    }
    return false;
  };

  const clearPhoto = () => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        context.fillStyle = "#AAA";
        context.fillRect(0, 0, canvas.width, canvas.height);

        const data = canvas.toDataURL("image/png");
        if (photoRef.current) {
          photoRef.current.setAttribute("src", data);
        }
      }
    }
  };

  const takePicture = (e) => {
    e.preventDefault();
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext("2d");
      if (context) {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        context.drawImage(video, 0, 0, canvas.width, canvas.height);

        // Chuyển đổi canvas thành data URL
        const data = canvas.toDataURL("image/png");
        if (!frontImage) {
          setFrontImage(data); // Lưu ảnh vào frontImage nếu chưa có
        } else if (!backImage) {
          setBackImage(data); // Lưu ảnh vào backImage nếu frontImage đã có
        }
        setCheckVideo(false);
      }
    } else {
      clearPhoto();
      setCheckVideo(false);
    }
  };

  const uploadFile = (values, type) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("trucdien", values);

    const request = fetch(`${apiUrl}/uploadFile`, {
      method: "POST",
      body: formData,
      signal: abortController.signal,
    });
    handleApiUpFile(request, type);
  };

  const handleApiUpFile = (request, type) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setLoading(false);
        if (json.iRs > 0 && json.data.trucdien) {
          if (type === "TRUOC") {
            setValueImgT(json.data.trucdien);
          } else if (type === "SAU") setValueImgS(json.data.trucdien);
        } else {
          _handleToast(getMsgByErrorCode(json.data.exitCode), "error");
          setCheckImgVal(true);
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        setCheckImgVal(true);
        setLoading(false);

        throw error;
      });
  };

  const getOcrUPS = () => {
    const params = {
      sid: dataAccount?.S_ID,
      anhMatTruoc: valueImgT,
      anhMatSau: valueImgS,
    };

    fetch(`${apiUrl}/ocrUPS`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    })
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setCheckValueOcr(false);
        if (json.iRs === 1 && json.data.exitCode === 0) {
          checkCardId(json?.data?.data);
          handleGetOrcData(json.data);

          let _dataAcc = Object.assign({}, dataAccount);
          _dataAcc.C_ANH_MAT_TRUOC = params.anhMatTruoc;
          _dataAcc.C_ANH_MAT_SAU = params.anhMatSau;
          _dataAcc.C_EDIT_FLAG = "0";

          dispatch(setDataAccount(_dataAcc));
          setCheckImgVal(false);
        } else {
          setCheckImgVal(true);
          setCheckValueOcr(false);
          setCheckSucc(false);
          let _dataAcc = Object.assign({}, dataAccount);
          _dataAcc.C_OCR_RESULT = json.data.exitCodeMessage;

          dispatch(setDataAccount(_dataAcc));
          _handleToast(getMsgByErrorCode(json.data.exitCode), "error");
          setTxtError(getMsgByErrorCode(json.data.exitCode));
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        _handleTakePhoto();

        throw error;
      });
  };

  const checkCardId = (values) => {
    const params = {
      user: "back",
      cmd: "CHECK_CARDID",
      param: {
        C_CARD_ID: values?.idNumber + "",
        C_ACCOUNT_NAME: values?.fullName + "",
        C_BIRTH_DAY: values?.dateOfBirth + "",
      },
      sid: dataAccount?.S_ID,
    };

    const request = fetch(`${apiUrl}/checkCardId`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify(params),
      signal: abortController.signal,
    });
    handleApiCardId(request);
  };

  const handleApiCardId = (request) => {
    return request
      .then(handleApiErrors)
      .then((response) => response.json())
      .then((json) => {
        setCheckSucc(false);
        setIsApiCompleted(true); // Đánh dấu API đã hoàn thành
        setProgress(100); // Cập nhật tiến trình lên 100%
        if (json.iRs > 0) {
          handleNextSubStep();
        } else {
          _handleToast(json.sRs, "error");
          setCheckValueOcr(false);
          _handleTakePhoto();
        }
      })
      .catch((error) => {
        _handleToast(t("processing-error"), "error");
        _handleTakePhoto();
        throw error;
      });
  };

  const _handleCloseModal = () => {
    setCheckValueOcr(false);
  };

  const _handleTakePhoto = () => {
    setValueImgS("");
    setValueImgT("");
    setFrontImage("");
    setBackImage("");
    setFileBackImage("");
    setFileFrontImage("");
    setProgress(0);
    setCheckImgVal(false);
    setCheckVideo(false);
  };

  const _handleToast = (msg, type = "info") => {
    const toastMsg = {
      id: Math.random(),
      type,
      title: t("notification"),
      msg,
    };
    dispatch(setToast(toastMsg));
  };

  const resetImgFront = () => {
    setFrontImage("");
    setFileFrontImage("");
    setValueImgT("");
    setCheckImgVal(false);
    setCheckVideo(false);
  };

  const resetImgBack = () => {
    setBackImage("");
    setFileBackImage("");
    setValueImgS("");
    setCheckImgVal(false);
    setCheckVideo(false);
  };

  return (
    <div className="flex flex-column gap-4">
      <div
        className={`d-flex align-items-center gap-6 flex-row`}
        style={{
          maxHeight: "439px",
        }}
      >
        <div
          className={`w-50 flex justify-center items-center  rounded-2xl h-100 ${
            error
              ? "border border-skin-weak"
              : frontImage
              ? " "
              : "border border-skin-green bg-skin-sur-secondary"
          } 
         `}
          style={{
            maxWidth: "477px",
          }}
        >
          <div
            className="w-100 h-100 d-flex flex-column justify-content-start items-center rounded-2xl gap-2"
            style={{
              padding: "8px 12px",
            }}
          >
            <span className="text-sm font-medium text-skin-title">
              {t("cccd-front")}
            </span>
            <div
              className="flex justify-center items-center relative"
              style={{
                width: "100%",
                height: "250px",
              }}
            >
              {error ? (
                <div className="flex flex-column gap-2 items-center justify-center">
                  <IconWarning />
                </div>
              ) : (
                <div className="w-100 h-100 d-flex flex-column justify-content-center align-items-center">
                  {!frontImage ? (
                    <div className="video-container">
                      <video
                        id="video"
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted={true}
                      ></video>
                      <div
                        className="absolute w-100 h-100 d-flex align-items-center justify-content-center"
                        style={{
                          top: 0,
                        }}
                      >
                        <div className="relative w-100 h-100">
                          <IconVector className="corner-border-l-t" />
                          <IconVector className="corner-border-l-b" />
                          <IconVector className="corner-border-r-t" />
                          <IconVector className="corner-border-r-b" />
                        </div>
                      </div>
                      {!checkVideo && (
                        <div className="loader text-lg font-semibold text-skin-title text-center"></div>
                      )}
                    </div>
                  ) : (
                    <img
                      src={frontImage}
                      alt="Ảnh cccd mặt trước"
                      style={{
                        width: "362px",
                        height: "236px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {error && (
              <div className="flex flex-column gap-2 items-center justify-center">
                <span className="text-base font-semibold text-skin-title text-center">
                  {error}
                </span>
                <span className="text-sm font-normal text-skin-body text-center">
                  {t("err-cam")}
                </span>
              </div>
            )}

            {checkImgVal ? (
              <span className="text-lg font-semibold text-skin-red text-center">
                {t("err-check-img")}
              </span>
            ) : (
              <span className="text-lg font-semibold text-skin-title text-center">
                {!error && !frontImage ? t("suggest-take-img") : ""}
              </span>
            )}

            <div className="flex justify-content-center items-center">
              {!error && !frontImage ? (
                <button
                  id="startbutton"
                  onClick={takePicture}
                  className={`text-skin-title border-none bg-skin-purple`}
                  style={{
                    padding: "0px 16px",
                    borderRadius: "1000px",
                    height: "40px",
                  }}
                  ref={btnRef}
                >
                  {t("take-img")}
                </button>
              ) : !error && frontImage ? (
                <button
                  id="startbutton"
                  onClick={() => resetImgFront()}
                  className={`text-skin-title border-none bg-skin-inversewhite`}
                  style={{
                    padding: "0px 16px",
                    borderRadius: "1000px",
                    height: "40px",
                  }}
                >
                  {t("take-img-l")}
                </button>
              ) : (
                ""
              )}
            </div>
            {!error && !frontImage && (
              <span className="text-sm text-skin-subdued font-medium">
                {t("or-input")}
                {' "'}
                <span className="text-skin-title">F</span>
                {'"'} {t("to-take-img")}
              </span>
            )}
          </div>
        </div>
        <div
          className={`w-50 flex justify-center items-center rounded-2xl h-100 ${
            error
              ? "border border-skin-weak"
              : frontImage && !backImage
              ? "border border-skin-green bg-skin-sur-secondary"
              : !frontImage && !backImage
              ? "border border-skin-weak border-dashed"
              : ""
          }`}
          style={{
            maxWidth: "477px",
          }}
        >
          <div
            className="w-100 h-100 d-flex flex-column justify-content-start items-center rounded-2xl gap-2"
            style={{
              padding: "8px 12px",
            }}
          >
            <span className="text-sm font-medium text-skin-title">
              {t("cccd-back")}
            </span>
            <div
              className="flex justify-center items-center relative"
              style={{
                width: "100%",
                height: "250px",
              }}
            >
              {error ? (
                <div className="flex flex-column gap-2 items-center justify-center">
                  <IconWarning />
                </div>
              ) : (
                <div className="w-100 h-100 flex justify-content-center align-items-center">
                  {frontImage && !backImage ? (
                    <div className="video-container">
                      <video
                        id="video"
                        ref={videoRef}
                        autoPlay
                        playsInline
                        muted={true}
                      ></video>
                      <div
                        className="absolute w-100 h-100 d-flex align-items-center justify-content-center"
                        style={{
                          top: 0,
                        }}
                      >
                        <IconVector className="corner-border-l-t" />
                        <IconVector className="corner-border-l-b" />
                        <IconVector className="corner-border-r-t" />
                        <IconVector className="corner-border-r-b" />
                      </div>
                      {!checkVideo && (
                        <div className="loader text-lg font-semibold text-skin-title text-center"></div>
                      )}
                    </div>
                  ) : (
                    <img
                      src={
                        backImage
                          ? backImage
                          : process.env.PUBLIC_URL + `/images/res/img-cccds.png`
                      }
                      alt="Ảnh cccd mặt sau"
                      style={{
                        width: "362px",
                        height: "236px",
                        objectFit: "cover",
                      }}
                    />
                  )}
                </div>
              )}
            </div>
            {error && (
              <div className="flex flex-column gap-2 items-center justify-center">
                <span className="text-base font-semibold text-skin-title text-center">
                  {error}
                </span>
                <span className="text-sm font-normal text-skin-body text-center">
                  {t("err-cam")}
                </span>
              </div>
            )}
            {checkImgVal ? (
              <span className="text-lg font-semibold text-skin-red text-center">
                {t("err-check-img")}
              </span>
            ) : (
              <span className="text-lg font-semibold text-skin-title  text-center">
                {frontImage && !backImage ? t("suggest-take-img") : ""}
              </span>
            )}
            <div className="flex justify-content-center items-center">
              {!error && frontImage && !backImage ? (
                <button
                  id="startbutton"
                  onClick={takePicture}
                  className={`text-skin-title border-none bg-skin-purple`}
                  style={{
                    padding: "0px 16px",
                    borderRadius: "1000px",
                    height: "40px",
                  }}
                  ref={btnRef}
                >
                  {t("take-img")}
                </button>
              ) : !frontImage && !backImage ? (
                ""
              ) : (
                <button
                  id="startbutton"
                  onClick={() => resetImgBack()}
                  className={`text-skin-title border-none bg-skin-inversewhite`}
                  style={{
                    padding: "0px 16px",
                    borderRadius: "1000px",
                    height: "40px",
                  }}
                >
                  {t("take-img-l")}
                </button>
              )}
            </div>
            {!error && frontImage && !backImage && (
              <span className="text-sm text-skin-subdued font-medium">
                {t("or-input")} {' "'}
                <span className="text-skin-title">F</span>
                {'"'} {t("to-take-img")}
              </span>
            )}
          </div>
        </div>
      </div>
      <canvas id="canvas" ref={canvasRef} hidden></canvas>

      <div className="d-flex flex-row-reverse justify-content-start gap-2 w-button">
        {valueImgS && valueImgT && (
          <button
            className="btn-submit success"
            onClick={() => {
              setCheckSucc(true);
            }}
          >
            {t("continue-step")}
          </button>
        )}
        <button className="btn-close" onClick={() => handlePreSubStep()}>
          {t("pre")}
        </button>
      </div>
      {checkValueOcr && (
        <ModalResAuth
          handleCloseModal={_handleCloseModal}
          progress={progress}
          titleText={t("auth-inf")}
          detailText={t("please-wait-inf")}
        />
      )}
      {loading && <div className="loader-full"></div>}
    </div>
  );
}

const mapStateToProps = (state) => {
  const getDataAccount = makeGetDataAccount();

  return {
    dataAccount: getDataAccount(state),
  };
};

export default connect(mapStateToProps)(
  translate("translations")(FormAuthDocs)
);

function useKey(key, cb) {
  const callback = useRef(cb);

  useEffect(() => {
    callback.current = cb;
  });

  useEffect(() => {
    function handle(event) {
      if (event.code === key) {
        callback.current(event);
      } else if (
        key === "w" &&
        (event.keyCode === 231 || event.keyCode === 87)
      ) {
        //w/W/ư ====> bán
        callback.current(event);
      } else if (key === "q" && event.keyCode === 81) {
        //q/Q ====> mua
        callback.current(event);
      } else if (key === "f" && event.keyCode === 70) {
        //f/F ====> submit form
        callback.current(event);
      } else if (key === "ctrls" && event.keyCode === 83 && event.ctrlKey) {
        event.preventDefault();
        callback.current(event);
      } else if (key === "ctrlx" && event.keyCode === 88 && event.ctrlKey) {
        callback.current(event);
      }
    }

    document.addEventListener("keydown", handle);
    return () => document.removeEventListener("keydown", handle);
  }, [key]);
}
